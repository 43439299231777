import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    timeRange: String
  }

  connect() {
    // Set initial time range from the button's data attribute
    const button = this.element
    this.timeRangeValue = button.dataset.turboStreamTimeRange
  }

  get() {
    // Update the time range value when clicking a button
    this.timeRangeValue = this.element.dataset.turboStreamTimeRange

    // Update all button states
    const buttons = document.querySelectorAll('[data-turbo-stream-time-range]')
    buttons.forEach(button => {
      const timeRange = button.dataset.turboStreamTimeRange
      if (timeRange === this.timeRangeValue) {
        button.classList.add('bg-white', 'shadow', 'text-gray-900')
        button.classList.remove('text-gray-500', 'hover:text-gray-700')
      } else {
        button.classList.remove('bg-white', 'shadow', 'text-gray-900')
        button.classList.add('text-gray-500', 'hover:text-gray-700')
      }
    })

    // Update the URL to reflect the new time range and reset page
    const url = new URL(window.location.href)
    url.searchParams.set('time_range', this.timeRangeValue)
    url.searchParams.set('tweets_page', '1')
    window.history.pushState({}, '', url)

    fetch(this.urlValue, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Turbo-Frame": "tweets_section"
      }
    }).then(response => {
      if (response.ok) {
        return response.text()
      }
      throw new Error('Network response was not ok')
    }).then(html => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, 'text/html')
      const turboStream = doc.querySelector('turbo-stream')
      if (turboStream) {
        turboStream.action = 'update'
        document.body.appendChild(turboStream)
      }
    })
  }
}
