import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button"]
  static values = {
    expanded: { type: Boolean, default: false },
    showText: String,
    hideText: String
  }

  connect() {
    this.updateButtonText()
  }

  toggle() {
    this.expandedValue = !this.expandedValue
    this.updateButtonText()
  }

  updateButtonText() {
    const text = this.expandedValue ? this.hideTextValue : this.showTextValue
    if (this.hasButtonTarget && text) {
      this.buttonTarget.textContent = text
    }
  }

  expandedValueChanged() {
    if (this.hasContentTarget) {
      this.contentTarget.classList.toggle("hidden", !this.expandedValue)
    }
  }
}
