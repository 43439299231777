import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["trigger", "icon"]

  toggle(event) {
    // Get the next row (metrics details)
    const metricsRow = this.element.nextElementSibling
    if (!metricsRow || !metricsRow.hasAttribute('data-metrics-details')) return

    // Toggle visibility
    const isVisible = metricsRow.style.display !== 'none'
    metricsRow.style.display = isVisible ? 'none' : 'table-row'
    
    // Rotate icon
    if (this.hasIconTarget) {
      this.iconTarget.style.transform = isVisible ? '' : 'rotate(180deg)'
    }
  }
}
