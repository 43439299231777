import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Add unique IDs to each accordion item
    this.element.querySelectorAll('[data-accordion-item]').forEach((item, index) => {
      const button = item.querySelector('button')
      const content = item.querySelector('[data-accordion-content]')
      const icon = item.querySelector('[data-accordion-icon]')
      
      button.dataset.accordionId = index
      content.dataset.accordionId = index
      icon.dataset.accordionId = index
    })
  }

  toggle(event) {
    const button = event.currentTarget
    const id = button.dataset.accordionId
    const content = this.element.querySelector(`[data-accordion-content][data-accordion-id="${id}"]`)
    const icon = this.element.querySelector(`[data-accordion-icon][data-accordion-id="${id}"]`)

    // Toggle content visibility
    content.classList.toggle('hidden')

    // Rotate icon when open
    icon.style.transform = content.classList.contains('hidden') ? 'rotate(0deg)' : 'rotate(180deg)'
    icon.style.transition = 'transform 0.2s ease-in-out'
  }
}
