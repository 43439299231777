// Configure your import map in config/importmap.rb

import "@hotwired/turbo-rails"
import ReactOnRails from 'react-on-rails'
import Navigation from '../bundles/Navigation/components/Navigation'
import Flash from '../bundles/Flash/components/Flash'

// Stimulus setup
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
// Change the path to look in the correct directory
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Import controllers directly
import HoverCardController from "../controllers/hover_card_controller"
import ExpandableRowController from "../controllers/expandable_row_controller"
import TabsController from "../controllers/tabs_controller"
import AccordionController from "../controllers/accordion_controller"
import ClipboardController from "../controllers/clipboard_controller"
import VideoController from "../controllers/video_controller"
import NotificationController from "../controllers/notification_controller"
import TurboStreamController from "../controllers/turbo_stream_controller"
import DropdownController from "../controllers/dropdown_controller"
import BannerController from "../controllers/banner_controller"
import ToggleController from "../controllers/toggle_controller"

// Register all controllers
application.register("hover-card", HoverCardController)
application.register("expandable-row", ExpandableRowController)
application.register("tabs", TabsController)
application.register("accordion", AccordionController)
application.register("clipboard", ClipboardController)
application.register("video", VideoController)
application.register("notification", NotificationController)
application.register("turbo-stream", TurboStreamController)
application.register("dropdown", DropdownController)
application.register("banner", BannerController)
application.register("toggle", ToggleController)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// Register React components
ReactOnRails.register({
  Navigation,
  Flash
})
