import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]
  static values = { text: String }

  copy(event) {
    navigator.clipboard.writeText(this.textValue)
    this.buttonTarget.textContent = "Copied!"
    
    setTimeout(() => {
      this.buttonTarget.textContent = "Copy"
    }, 2000)
  }
}
