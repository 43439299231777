import * as React from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/20/solid'

declare global {
  interface Window {
    currentUser?: {
      image_url: string;
      name: string;
      has_wallet: boolean;
    };
  }
}

export default function Navigation() {
  const openTwitterIntent = () => {
    window.location.href = '/tweet'
  }

  const handleSignOut = async () => {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
      await fetch('/signout', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken || '',
        },
        credentials: 'same-origin'
      });
      window.location.href = '/';
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  const isCurrentPath = (path: string) => {
    return window.location.pathname === path;
  }

  return (
    <Disclosure as="nav" className="bg-white shadow">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="-ml-2 mr-2 flex items-center md:hidden">
              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ButterflyBush focus:ring-offset-2">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="block size-6 group-data-[open]:hidden" />
                <XMarkIcon aria-hidden="true" className="hidden size-6 group-data-[open]:block" />
              </DisclosureButton>
            </div>
            <div className="flex shrink-0 items-center">
              <a href="/" data-turbo="false">
                <img
                  alt="The Plooshies"
                  src="/assets/logo.png"
                  className="h-8 w-auto"
                />
              </a>
            </div>
            <div className="hidden md:ml-6 md:flex md:space-x-8">
            <a
                href="/seasons"
                data-turbo="false"
                className={`inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium ${
                  isCurrentPath('/seasons') || isCurrentPath('/')
                    ? 'border-ButterflyBush text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                SocialFi
              </a>
              <a
                href="/profile"
                data-turbo="false"
                className={`inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium ${
                  isCurrentPath('/profile')
                    ? 'border-ButterflyBush text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                Dashboard
              </a>
              <a
                href="/faq"
                data-turbo="false"
                className={`inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium ${
                  isCurrentPath('/faq')
                    ? 'border-ButterflyBush text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                FAQ
              </a>
            </div>
          </div>
          <div className="flex items-center">
            {window.currentUser ? (
              <>
                <div className="shrink-0">
                  <button
                    type="button"
                    onClick={openTwitterIntent}
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-ButterflyBush px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ButterflyBush/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ButterflyBush"
                  >
                    <PlusIcon aria-hidden="true" className="-ml-0.5 size-5" />
                    New Tweet
                  </button>
                </div>
                <div className="hidden md:ml-4 md:flex md:shrink-0 md:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-ButterflyBush focus:ring-offset-2">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                          alt=""
                          src={window.currentUser?.image_url}
                          className="size-8 rounded-full"
                        />
                      </MenuButton>
                    </div>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <MenuItem>
                        <a
                          href="/profile"
                          data-turbo="false"
                          className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none"
                        >
                          Your Profile
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="/wallet"
                          data-turbo="false"
                          className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none"
                        >
                          {window.currentUser?.has_wallet ? "Update Wallet" : "Add Wallet"}
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <button
                          onClick={handleSignOut}
                          className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none"
                        >
                          Sign out
                        </button>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </>
            ) : (
              <div className="shrink-0">
                <a
                  href="/login"
                  data-turbo="false"
                  className="group relative inline-flex items-center px-8 py-2 text-sm font-medium rounded-md text-white bg-ButterflyBush hover:bg-ButterflyBush/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ButterflyBush min-w-[180px] justify-center"
                >
                  Login
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <DisclosurePanel className="md:hidden">
        <div className="space-y-1 pb-3 pt-2">
          <DisclosureButton
            as="a"
            href="/seasons"
            data-turbo="false"
            className={`block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6 ${
              isCurrentPath('/seasons') || isCurrentPath('/')
                ? 'border-ButterflyBush bg-ButterflyBush/10 text-ButterflyBush'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
            }`}
          >
            SocialFi
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="/profile"
            data-turbo="false"
            className={`block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6 ${
              isCurrentPath('/profile')
                ? 'border-ButterflyBush bg-ButterflyBush/10 text-ButterflyBush'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
            }`}
          >
            Dashboard
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="/faq"
            data-turbo="false"
            className={`block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6 ${
              isCurrentPath('/faq')
                ? 'border-ButterflyBush bg-ButterflyBush/10 text-ButterflyBush'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
            }`}
          >
            FAQ
          </DisclosureButton>
        </div>
        {window.currentUser && (
          <div className="border-t border-gray-200 pb-3 pt-4">
            <div className="flex items-center px-4 sm:px-6">
              <div className="shrink-0">
                <img
                  alt=""
                  src={window.currentUser?.image_url}
                  className="size-10 rounded-full"
                />
              </div>
              <div className="ml-3">
                <div className="text-base font-medium text-gray-800">{window.currentUser?.name}</div>
              </div>
            </div>
            <div className="mt-3 space-y-1">
              <DisclosureButton
                as="a"
                href="/profile"
                data-turbo="false"
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
              >
                Your Profile
              </DisclosureButton>
              <DisclosureButton
                as="a"
                href="/wallet"
                data-turbo="false"
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
              >
                {window.currentUser?.has_wallet ? "Update Wallet" : "Add Wallet"}
              </DisclosureButton>
              <DisclosureButton
                as="button"
                onClick={handleSignOut}
                className="block w-full px-4 py-2 text-left text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
              >
                Sign out
              </DisclosureButton>
            </div>
          </div>
        )}
      </DisclosurePanel>
    </Disclosure>
  )
}