import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  connect() {
    // No need for initialization - Turbo handles the initial state
  }

  select(event) {
    if (event.type === "change") {
      // For mobile dropdown, trigger the corresponding button
      const value = event.currentTarget.value
      const button = this.element.querySelector(`[data-tabs-value="${value}"]`)
      if (button) {
        button.click()
      }
      return
    }
    
    // For desktop tabs, update the UI state
    const button = event.currentTarget
    const value = button.dataset.tabsValue

    // Show the selected frame and hide others
    this.panelTargets.forEach(panel => {
      panel.classList.toggle("hidden", panel.dataset.tabsValue !== value)
    })
    
    // Update active states for desktop tabs
    this.tabTargets.forEach(tab => {
      const isActive = tab.dataset.tabsValue === value
      tab.setAttribute("aria-current", isActive ? "page" : "false")
      tab.classList.toggle("bg-white", isActive)
      tab.classList.toggle("shadow", isActive)
      tab.classList.toggle("text-gray-900", isActive)
      tab.classList.toggle("text-gray-500", !isActive)
    })

    // Update mobile dropdown if it exists
    const select = this.element.querySelector("select")
    if (select) {
      select.value = value
    }
  }
}
