import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["trigger", "content"]
  
  connect() {
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    
    this.triggerTarget.addEventListener("mouseenter", this.show)
    this.triggerTarget.addEventListener("mouseleave", this.hide)
    this.contentTarget.addEventListener("mouseenter", this.show)
    this.contentTarget.addEventListener("mouseleave", this.hide)
  }

  disconnect() {
    this.triggerTarget.removeEventListener("mouseenter", this.show)
    this.triggerTarget.removeEventListener("mouseleave", this.hide)
    this.contentTarget.removeEventListener("mouseenter", this.show)
    this.contentTarget.removeEventListener("mouseleave", this.hide)
  }

  show() {
    this.contentTarget.classList.remove("hidden")
  }
  
  hide() {
    this.contentTarget.classList.add("hidden")
  }
}