import React, { useEffect } from 'react'
import { useToast } from "@/components/hooks/use-toast"
import { Toaster } from "@/components/ui/toaster"

interface FlashProps {
  notice?: string | null
  alert?: string | null
}

export default function Flash({ notice, alert }: FlashProps) {
  const { toast } = useToast()
  console.log('useToast hook loaded:', !!toast)

  useEffect(() => {
    console.log('Flash useEffect triggered')
    if (notice) {
      console.log('Showing notice toast:', notice)
      toast({
        description: notice,
      })
    }
    if (alert) {
      console.log('Showing alert toast:', alert)
      toast({
        description: alert,
        variant: "destructive",
      })
    }
  }, [notice, alert, toast])

  console.log('Rendering Toaster component')
  return <Toaster />
}
